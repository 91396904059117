import gql from "graphql-tag";

export const CREATE_PROJECT_MUTATION = gql`
    mutation createProject($input: ProjectInput) {
        createProject(input: $input) {
            name
            slug
            path
        }
    }
`
