<template>
  <Modal :class="{'is-active': isProjectModalActive }">
    <Box class="is-shadowless p-6">
        <h2 class="title is-3">New Project</h2>
        <article v-if="project" class="message is-success mb-2">
          <div class="message-body py-4">
            Created at <a :href="project.web_url" target="_blank">{{ project.web_url }}</a>
          </div>
        </article>
        <form @submit.prevent="submitForm">

          <div class="field">
            <label class="label"></label>
            <div class="control">
              <input
                v-model="project_name"
                class="input"
                type="text"
                required
                placeholder="Project Name"
                :disabled="loading"
                />
            </div>
          </div>

          <div class="field">
            <label class="label"></label>
            <div class="control">
              <textarea
                v-model="desc"
                class="textarea"
                placeholder="Description"
                :disabled="loading"
              />
            </div>
          </div>

          <div class="field is-grouped mt-5">
            <div class="control">
              <button class="button is-link" :class="{'is-loading': loading }" :disabled="loading">Submit</button>
            </div>
            <div class="control">
              <button class="button is-link is-light" @click.prevent="toggleProjectModal"  :disabled="loading">Cancel</button>
            </div>
          </div>
        </form>

    </Box>

    <template v-slot:modal-close>
      <!-- <button class="modal-close is-large" aria-label="close" @click="toggleProjectModal"></button> -->
    </template>
  </Modal>

</template>

<script>
import { defineAsyncComponent, reactive, toRefs } from 'vue'
import useGeneral from '@/use/useGeneral'
import { createProjectAPI } from "@/use/useGitlabApi";
// import { getAllProjects } from '@/use/useProject'
import { useMutation } from "@vue/apollo-composable";
import { CREATE_PROJECT_MUTATION } from "@/graphql/mutations/projectMutation";

export default {
  components: {
    Modal: defineAsyncComponent(() => import('@/components/ds/Modal')),
    Box: defineAsyncComponent(() => import('@/components/ds/Box')),
  },
  setup() {
    const state = reactive({
      project_name: '',
      desc: '',
      visibility: 'internal'
    })

    const {mutate: createProjectCms} = useMutation(CREATE_PROJECT_MUTATION, {clientId: "cmsClient"});

    const {isProjectModalActive, toggleProjectModal} = useGeneral();

    const {loading, errors, project, createProject} = createProjectAPI()

    // const { refetch } = getAllProjects()

    const submitForm = async () => {
      await createProject({name: state.project_name, namespace_id: 26, visibility: state.visibility, description: state.desc })

      await createProjectCms({
          input: {
            name: project.value.name,
            slug: project.value.path,
            path: project.value.path_with_namespace,
            status: "OPEN",
            project_id: project.value.id
          }
        })

        // refetch()
        toggleProjectModal()
    }

    return {
      ...toRefs(state),
      isProjectModalActive,
      toggleProjectModal,
      submitForm,
      project,
      loading,
      errors
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
